const BASE_URL = process.env.https://abp.villapinedo.nl;

const URLS = {
  BASE: BASE_URL,
  CMSKIT: `${BASE_URL}/api/cms-kit`,
  FILE: `${BASE_URL}/api/file`,
  APP: `${BASE_URL}/api/app`,
  PAGES: {
    FORUM: "/forum",
    FORUM_PARENTS: "/forum-ouders",
    ABOUT: "/over-villa-pinedo",
    BUDDY_INFO_PARENTS: "/buddy-meer-info",
    OPEN_LETTERS_PARENTS: "/open-brieven-ouders",
    VISION_MISSION: "/alles-over-villa-pinedo",
    /** Homepage beroepskrachten */
    ACADEMY: "/academy",
    TRAINING_PROFESSIONALS: "/academy/aanbod",
    COLLABORATE: "/samenwerken",
    MILESTONES: "/mijlpalen",
    MEDIA_REPORTS: "/mediaberichten",
    MEDIA_REPORTS_DETAIL: "/in-de-media",
    PARENT_CHILD_CONTRACT: "/ouder-kind-contract",
    VACANCIES: "/vacatures",
    SPONSOR_BUDDY: "/steun-een-buddy",
    SPONSOR_BUDDY_FORM: "/steun-een-buddy/buddy-sponsoren-aanmelden",
    TRAINING_PARENTS: "/training-voor-ouders",
    TEAM_VILLA_PINEDO: "/wie-zijn-wij",
    TEACHERS: "/docenten",
    WORKSHOPS: "/bijeenkomsten",
    RESEARCH_REPORT: "/onderzoeksrapport-universiteit-utrecht ",
    MUNICIPALITY: "/gemeente",
    COMPANY: "/bedrijf",
    WHAT_CAN_YOU_DO: "/wat-kan-jij-doen",
    DONATE: "/doneren",
    TIPS: "/tips",
    STORIES: "/verhalen",
  },
};

export default URLS;
